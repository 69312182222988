import React, { useContext } from 'react';
import { FilterContext } from '../globalState';
import { motion } from 'framer-motion';
import { useHistory } from 'react-router-dom';
import { CopyToClipboard } from 'react-copy-to-clipboard';

function Card({
	name,
	relocation,
	location,
	linkedin,
	file,
	expertise,
	presentation,
	position,
	employee,
	index
}) {
	const history = useHistory();
	const { setTheModal, clipboardOn, setLinkedInData } = useContext(
		FilterContext
	);
	const cardVariant = {
		init: {
			opacity: 0,
			y: 100
		},
		anim: {
			opacity: 1,
			y: 0,
			transition: {
				delay: index * 0.15
			}
		}
	};

	const linkedinData = !!linkedin
		? linkedin.includes('@')
			? `mailto:${linkedin}`
			: linkedin.includes('https://www.')
			? linkedin.replace('https://www.', 'https://')
			: linkedin.includes('www.')
			? linkedin.replace('www.', 'https://')
			: linkedin.includes('https://linkedin')
			? linkedin
			: `https://www.linkedin.com/search/results/all/?keywords=${encodeURI(
					linkedin
			  )}&origin=GLOBAL_SEARCH_HEADER`
		: null;

	return (
		<motion.div
			initial="init"
			animate="anim"
			variants={cardVariant}
			whileHover={{
				scale: 1.05
			}}
			// className="bg-white shadow-md rounded-lg mb-8 transform scale-100 hover:scale-105 transition duration-200 ease-in-out"
			className="bg-white shadow-md rounded-lg mb-8"
		>
			<div className="content p-6">
				<h3 className="text-xl">{name}</h3>
				<p className="text-lg text-lightBlue capitalize">{position}</p>
				<p className="text-grayText">
					{location} -{' '}
					{relocation ? (
						<span className="text-grayTextLight">
							Disponible a reubicarse
						</span>
					) : (
						<span className="text-grayTextLight">
							No disponible a reubicarse
						</span>
					)}
				</p>
				<p className="text-black mt-4 leading-tight line-clamp-5">
					{presentation}
				</p>
				<p className="text-lightBlue text-sm mt-3 font-medium">
					Experiencia:
				</p>
				<p className="text-grayText text-sm line-clamp-3">
					{expertise}
				</p>
				<button
					onClick={() => {
						// window.umami.trackEvent(
						// 	`${employee}`,
						// 	'userDetail_btn'
						// );
						history.push({
							search: `?id=${employee}`
						});
						setLinkedInData(linkedinData);
						setTheModal(true);
					}}
					className="text-lightBlue mt-2 underline"
				>
					Leer más
				</button>
			</div>
			<div className="w-full bg-veryLightBlue py-4 px-6 flex items-center justify-end">
				{linkedin !== null && (
					<a
						// onClick={() =>
						// 	window.umami.trackEvent(
						// 		`${employee}`,
						// 		'linkedin_home_btn'
						// 	)
						// }
						href={linkedinData}
						target="_blank"
						rel="noreferrer"
						className={`text-blueGray hover:text-black w-1/3 flex items-center justify-start ${
							file === null ? 'mr-4' : ''
						}`}
					>
						{linkedinData.includes('mailto:') ? (
							<svg
								xmlns="http://www.w3.org/2000/svg"
								width="24"
								height="24"
								fill="none"
								stroke="currentColor"
								strokeLinecap="round"
								strokeLinejoin="round"
								strokeWidth="2"
								className="stroke-current flex-shrink-0 h-4 xl:h-5 w-4 xl:w-5"
								viewBox="0 0 24 24"
							>
								<path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z" />
								<path d="M22 6L12 13 2 6" />
							</svg>
						) : (
							<svg
								xmlns="http://www.w3.org/2000/svg"
								width="24"
								height="24"
								viewBox="0 0 24 24"
								className="fill-current flex-shrink-0 h-4 xl:h-5 w-4 xl:w-5"
							>
								<circle cx="4.983" cy="5.009" r="2.188" />
								<path d="M9.237 8.855v12.139h3.769v-6.003c0-1.584.298-3.118 2.262-3.118 1.937 0 1.961 1.811 1.961 3.218v5.904H21v-6.657c0-3.27-.704-5.783-4.526-5.783-1.835 0-3.065 1.007-3.568 1.96h-.051v-1.66H9.237zM3.095 8.855H6.87V20.994H3.095z" />
							</svg>
						)}
						<p className={'mx-2 leading-none text-sm xl:text-base'}>
							{linkedin.includes('@') ? 'Correo' : 'LinkedIn'}
						</p>
					</a>
				)}
				{file !== null && (
					<a
						// onClick={() =>
						// 	window.umami.trackEvent(
						// 		`${employee}`,
						// 		'cv_home_btn'
						// 	)
						// }
						href={file}
						target="_blank"
						rel="noreferrer"
						className="text-blueGray hover:text-black w-auto flex items-center justify-start mx-4"
					>
						<svg
							xmlns="http://www.w3.org/2000/svg"
							width="32"
							height="32"
							fill="none"
							stroke="currentcolor"
							strokeLinecap="round"
							strokeLinejoin="round"
							strokeWidth="2"
							viewBox="0 0 32 32"
							className="stroke-current h-4 xl:h-5 w-4 xl:w-5 flex-shrink-0"
						>
							<path d="M10 9 L10 24 C10 28 13 30 16 30 19 30 22 28 22 24 L22 6 C22 3 20 2 18 2 16 2 14 3 14 6 L14 23 C14 24 15 25 16 25 17 25 18 24 18 23 L18 9" />
						</svg>
						<p className="mx-2 leading-none text-sm xl:text-base">
							CV
						</p>
					</a>
				)}
				<CopyToClipboard
					text={`${window.location.href}?id=${employee}`}
					onCopy={() => {
						// window.umami.trackEvent(
						// 	`${employee}`,
						// 	'share_home_btn'
						// );
						clipboardOn();
					}}
				>
					<div className="text-blueGray hover:text-black w-1/3 flex items-center justify-start cursor-pointer">
						<svg
							xmlns="http://www.w3.org/2000/svg"
							width="24"
							height="24"
							viewBox="0 0 24 24"
							className="fill-current flex-shrink-0 h-4 lg:h-4 xl:h-5 w-4 lg:w-4 xl:w-5"
						>
							<path d="M3,12c0,1.654,1.346,3,3,3c0.794,0,1.512-0.315,2.049-0.82l5.991,3.424C14.022,17.734,14,17.864,14,18c0,1.654,1.346,3,3,3 s3-1.346,3-3s-1.346-3-3-3c-0.794,0-1.512,0.315-2.049,0.82L8.96,12.397C8.978,12.266,9,12.136,9,12s-0.022-0.266-0.04-0.397 l5.991-3.423C15.488,8.685,16.206,9,17,9c1.654,0,3-1.346,3-3s-1.346-3-3-3s-3,1.346-3,3c0,0.136,0.022,0.266,0.04,0.397 L8.049,9.82C7.512,9.315,6.794,9,6,9C4.346,9,3,10.346,3,12z" />
						</svg>
						<p className="mx-2 leading-none text-sm xl:text-base">
							Compartir
						</p>
					</div>
				</CopyToClipboard>
			</div>
		</motion.div>
	);
}

export default Card;
