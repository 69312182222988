import React from 'react';
import { ReactComponent as Logo } from '../assets/aeromexico-logo.svg';

function Nav() {
	return (
		<nav className="w-full bg-black h-nav flex items-center justify-start sticky top-0 z-50">
			<div className="container mx-auto px-10">
				<Logo />
			</div>
		</nav>
	);
}

export default Nav;
