import React, { useState, useEffect, useContext } from 'react';
import { FilterContext } from '../../globalState';
import { AnimatePresence, motion } from 'framer-motion';
const array = require('lodash/array');

function Filters({ showTitle }) {
	const [allCards, setAllCards] = useState(null);
	const [allLocations, setAllLocations] = useState([]);
	const [thePlace, setThePlace] = useState('Todos');
	const [relocation, setRelocation] = useState(false);
	const { setTheUsers, resetUsers } = useContext(FilterContext);

	const handleRelocation = async () => {
		// window.umami.trackEvent(
		// 	relocation ? 'No reubicación' : 'Reubicación',
		// 	'relocation_btn'
		// );
		// ReactGA.event({
		// 	category: 'Event',
		// 	action: 'Botón de Reubicación',
		// 	value: !relocation
		// });
		setRelocation(!relocation);
		await resetUsers();
		setTimeout(function () {
			// console.log(thePlace);
			if (!relocation) {
				setRelocation(true);
				const tempArray = [];
				for (let i = 0; i < allCards.length; i++) {
					if (allCards[i].relocation) {
						if (thePlace === 'Todos') {
							tempArray.push(allCards[i]);
						} else {
							if (thePlace === allCards[i].location) {
								tempArray.push(allCards[i]);
							}
						}
					}
				}
				setTheUsers(tempArray);
			} else {
				setRelocation(false);
				if (thePlace === 'Todos') {
					setTheUsers(allCards);
				} else {
					const tempArray = [];
					allCards.forEach((item) => {
						if (item.location === thePlace) {
							tempArray.push(item);
						}
					});
					setTheUsers(tempArray);
				}
			}
		}, 250);
	};

	const handlePlace = async (e) => {
		// window.umami.trackEvent(e.target.value, 'place_search');
		// ReactGA.event({
		// 	category: 'Event',
		// 	action: 'Cambio de ubicación',
		// 	value: e.target.value
		// });
		// console.log('change on SELECT');
		await resetUsers();
		setTimeout(function () {
			const tempArray = [];
			if (!relocation) {
				for (let i = 0; i < allCards.length; i++) {
					if (e.target.value !== 'Todos') {
						if (allCards[i].location === e.target.value) {
							tempArray.push(allCards[i]);
						}
					} else {
						tempArray.push(allCards[i]);
					}
				}
				setThePlace(e.target.value);
				setTheUsers(tempArray);
			} else {
				for (let i = 0; i < allCards.length; i++) {
					if (e.target.value !== 'Todos') {
						if (allCards[i].location === e.target.value) {
							if (allCards[i].relocation) {
								tempArray.push(allCards[i]);
							}
						}
					} else {
						if (allCards[i].relocation) {
							tempArray.push(allCards[i]);
						}
					}
				}
				setThePlace(e.target.value);
				setTheUsers(tempArray);
			}
		}, 250);
	};

	useEffect(() => {
		// console.log('LLAMADO DE FETCH');
		if (allCards === null) {
			fetch(
				'https://cadmin.miaeromexico.com/usuarios-directorio-talentos'
			)
				.then((res) => res.json())
				.then((res) => {
					const tempLocate = [];
					for (let i = 0; i < res.length; i++) {
						const isThere = array.findIndex(
							tempLocate,
							function (o) {
								return o === res[i].location;
							}
						);
						if (isThere < 0) {
							tempLocate.push(res[i].location);
						}
					}
					setAllLocations(tempLocate);
					setAllCards(res);
					setTheUsers(res);
				})
				.catch((err) => console.log(err));
		}
	}, [setTheUsers, allCards]);

	return (
		<motion.div
			initial={{ opacity: 0 }}
			animate={{ opacity: 1 }}
			exit={{ opacity: 0 }}
			className="flex items-start justify-start flex-wrap"
		>
			<AnimatePresence exitBeforeEnter>
				{showTitle && (
					<motion.p
						initial={{ height: 0, opacity: 0 }}
						animate={{ height: 'auto', opacity: 1 }}
						exit={{ height: 0, opacity: 0 }}
						key="Sticker Holder Title"
						className="text-xl text-black font-medium w-full mb-2 overflow-hidden"
					>
						Directorio de Talento
					</motion.p>
				)}
			</AnimatePresence>
			<div className="input-holder relative">
				<label
					htmlFor="location"
					className="absolute top-0 bg-white left-2 px-2 transform -translate-y-1/2 text-xs"
				>
					Locaciones
				</label>
				<select
					className="border-2 border-border rounded py-3 px-4 w-48 md:w-56 text-sm md:text-base outline-none focus:outline-none focus:ring-0 focus:border-black"
					name="location"
					onChange={handlePlace}
				>
					<option value="Todos">Todas las ubicaciones</option>
					{allLocations.map((place, index) => (
						<option key={place + index} value={place}>
							{place}
						</option>
					))}
				</select>
			</div>
			<div className="relocation flex items-start justify-start flex-col ml-4">
				<p className="text-black text-xs mb-2 hidden md:block">
					Opción de reubicación
				</p>
				<p className="text-black text-xs mb-2 block md:hidden">
					Reubicación
				</p>
				<button
					onClick={handleRelocation}
					className={`${
						relocation ? 'bg-black' : 'bg-gray-200'
					} relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-offset-2 focus:ring-black`}
				>
					<span className="sr-only">Opción de reubicación</span>
					<span
						className={`${
							relocation ? 'translate-x-5' : 'translate-x-0'
						} inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200`}
					></span>
				</button>
			</div>
		</motion.div>
	);
}

export default Filters;
