import React, { useContext, useEffect, useState } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { FilterContext } from '../globalState';
import { LINKEDIN_FORMATTER } from '../util/linkedin-util';
import ReactMarkdown from 'react-markdown';
import { CopyToClipboard } from 'react-copy-to-clipboard';

function Detail() {
	const { userId, clipboardOn } = useContext(FilterContext);
	const [data, setData] = useState([]);
	const [linkedinData, setLinkedinData] = useState(null);

	useEffect(() => {
		fetch(`https://cadmin.miaeromexico.com/directorio-talento/${userId}`)
			.then((res) => res.json())
			.then(async (res) => {
				console.log(res.user);
				setLinkedinData(LINKEDIN_FORMATTER(res.user.linkedIn));
				setData(res.user);
			})
			.catch((err) => console.log(err));
	}, [userId]);

	const contentVariant = {
		init: {
			opacity: 0,
			y: 100
		},
		anim: {
			opacity: 1,
			y: 0,
			transition: {
				delay: 0.5
			}
		},
		exit: {
			opacity: 0,
			y: 100
		}
	};

	return (
		<AnimatePresence exitBeforeEnter>
			<motion.div
				key="detail-user-data"
				className="container mx-auto px-10 sm:px-0 flex items-center justify-center"
				initial={{ opacity: 0 }}
				animate={{ opacity: 1 }}
				exit={{ opacity: 0 }}
			>
				{data && (
					<motion.section
						variants={contentVariant}
						initial="init"
						animate="anim"
						exit="exit"
						className="w-full md:w-10/12 lg:w-6/12 flex flex-col items-start justify-center content py-16 px-10 bg-white overflow-hidden shadow-xl rounded-lg"
					>
						<article className="w-full">
							<h3 className="text-lightBlue leading-tight text-lg capitalize">
								{data.position}
							</h3>
							<h2 className="text-3xl text-black leading-tight font-medium capitalize">
								{data.name}
							</h2>
							<h4 className="text-grayText leading-tight">
								{data.location} -{' '}
								<span className="text-grayTextLight">
									{data.relocation
										? 'Disponible a reubicarse'
										: 'No disponible a reubicarse'}
								</span>
							</h4>
							<div className="share w-full flex items-center justify-start mt-4">
								{linkedinData !== null && (
									<a
										// onClick={() =>
										// 	window.umami.trackEvent(
										// 		userId,
										// 		'linkedin_detail_btn'
										// 	)
										// }
										href={linkedinData}
										target="_blank"
										rel="noreferrer"
										className={`text-lightBlue hover:text-black w-auto flex items-center justify-start underline cursor-pointer ${
											data.file === null ? 'mr-2' : ''
										}`}
									>
										{linkedinData.includes('mailto:') ? (
											<svg
												xmlns="http://www.w3.org/2000/svg"
												width="24"
												height="24"
												fill="none"
												stroke="currentColor"
												strokeLinecap="round"
												strokeLinejoin="round"
												strokeWidth="2"
												className="stroke-current flex-shrink-0 h-4 xl:h-5 w-4 xl:w-5"
												viewBox="0 0 24 24"
											>
												<path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z" />
												<path d="M22 6L12 13 2 6" />
											</svg>
										) : (
											<svg
												xmlns="http://www.w3.org/2000/svg"
												width="24"
												height="24"
												viewBox="0 0 24 24"
												className="fill-current flex-shrink-0 h-4 xl:h-5 w-4 xl:w-5"
											>
												<circle
													cx="4.983"
													cy="5.009"
													r="2.188"
												/>
												<path d="M9.237 8.855v12.139h3.769v-6.003c0-1.584.298-3.118 2.262-3.118 1.937 0 1.961 1.811 1.961 3.218v5.904H21v-6.657c0-3.27-.704-5.783-4.526-5.783-1.835 0-3.065 1.007-3.568 1.96h-.051v-1.66H9.237zM3.095 8.855H6.87V20.994H3.095z" />
											</svg>
										)}
										<p className="mx-2 leading-none text-sm xl:text-base">
											{linkedinData.includes('mailto:')
												? 'Correo'
												: 'LinkedIn'}
										</p>
									</a>
								)}
								{data.file !== null && (
									<a
										// onClick={() =>
										// 	window.umami.trackEvent(
										// 		userId,
										// 		'cv_detail_btn'
										// 	)
										// }
										href={data.file?.url}
										target="_blank"
										rel="noreferrer"
										className="text-lightBlue hover:text-black w-auto flex items-center justify-start mr-2 underline cursor-pointer"
									>
										<svg
											xmlns="http://www.w3.org/2000/svg"
											width="32"
											height="32"
											fill="none"
											stroke="currentcolor"
											strokeLinecap="round"
											strokeLinejoin="round"
											strokeWidth="2"
											viewBox="0 0 32 32"
											className="stroke-current h-4 xl:h-5 w-4 xl:w-5 flex-shrink-0"
										>
											<path d="M10 9 L10 24 C10 28 13 30 16 30 19 30 22 28 22 24 L22 6 C22 3 20 2 18 2 16 2 14 3 14 6 L14 23 C14 24 15 25 16 25 17 25 18 24 18 23 L18 9" />
										</svg>
										<p className="mx-2 leading-none text-sm xl:text-base">
											CV
										</p>
									</a>
								)}
								<CopyToClipboard
									text={window.location.href}
									onCopy={() => {
										// window.umami.trackEvent(
										// 	userId,
										// 	'share_detail_btn'
										// );
										clipboardOn();
									}}
								>
									<div className="text-lightBlue hover:text-black w-auto flex items-center justify-start underline cursor-pointer">
										<svg
											xmlns="http://www.w3.org/2000/svg"
											width="24"
											height="24"
											viewBox="0 0 24 24"
											className="fill-current flex-shrink-0 h-4 lg:h-4 xl:h-5 w-4 lg:w-4 xl:w-5"
										>
											<path d="M3,12c0,1.654,1.346,3,3,3c0.794,0,1.512-0.315,2.049-0.82l5.991,3.424C14.022,17.734,14,17.864,14,18c0,1.654,1.346,3,3,3 s3-1.346,3-3s-1.346-3-3-3c-0.794,0-1.512,0.315-2.049,0.82L8.96,12.397C8.978,12.266,9,12.136,9,12s-0.022-0.266-0.04-0.397 l5.991-3.423C15.488,8.685,16.206,9,17,9c1.654,0,3-1.346,3-3s-1.346-3-3-3s-3,1.346-3,3c0,0.136,0.022,0.266,0.04,0.397 L8.049,9.82C7.512,9.315,6.794,9,6,9C4.346,9,3,10.346,3,12z" />
										</svg>
										<p className="mx-2 leading-none text-sm xl:text-base">
											Compartir
										</p>
									</div>
								</CopyToClipboard>
							</div>
						</article>
						<article className="w-full flex flex-col items-center justify-center">
							<div className="relative w-full my-6">
								<div
									className="absolute inset-0 flex items-center"
									aria-hidden="true"
								>
									<div className="w-full border-t border-gray-300"></div>
								</div>
								<div className="relative flex justify-center">
									<span className="px-3 bg-white font-medium text-black">
										<svg
											className="h-5 w-5 text-black fill-current"
											xmlns="http://www.w3.org/2000/svg"
											fill="none"
											viewBox="0 0 20 20"
										>
											<path
												// fill="#6B7280"
												// fillRule="evenodd"
												d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z"
												clipRule="evenodd"
											/>
										</svg>
									</span>
								</div>
							</div>
							<div className="prose text-grayText w-full">
								<ReactMarkdown>
									{data.presentation}
								</ReactMarkdown>
							</div>
							<div className="expertise text-grayText w-full flex flex-col">
								<div className="relative w-full my-6">
									<div className="absolute inset-0 flex items-center">
										<div className="w-full border-t border-gray-300"></div>
									</div>
									<div className="relative flex justify-center">
										<span className="px-3 bg-white font-medium text-grayTextLight">
											Experiencia
										</span>
									</div>
								</div>
								<ReactMarkdown>{data.expertise}</ReactMarkdown>
							</div>
						</article>
					</motion.section>
				)}
			</motion.div>
		</AnimatePresence>
	);
}

export default Detail;
