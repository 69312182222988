import { useContext, useEffect } from 'react';
import Nav from './components/Nav';
import Header from './components/Header';
import Cards from './components/Cards';
import Detail from './components/Detail';
import Clipboard from './components/Clipboard';
import { FilterContext } from './globalState';
import { Switch, Route } from 'react-router-dom';
import { AnimatePresence } from 'framer-motion';
import ReactGA from 'react-ga';

ReactGA.initialize('G-BMF72CPLC8');

function App() {
	const {
		clipboardActive,
		theModal,
		setTheModal,
		setUserId,
		userId
	} = useContext(FilterContext);

	useEffect(() => {
		const urlParams = new URLSearchParams(window.location.search);
		console.log(urlParams.get('id'));
		if (urlParams.get('id') !== null) {
			ReactGA.pageview(window.location.pathname + window.location.search);
			setUserId(urlParams.get('id'));
			setTheModal(true);
		} else {
			ReactGA.pageview(window.location.pathname + window.location.search);
		}
	}, [setUserId, userId, setTheModal]);

	return (
		<Switch>
			<Route path="/">
				<Nav />
				<Header theModal={userId !== null ? true : false} />
				<section className="container mx-auto mt-10 mb-10">
					{!theModal ? <Cards /> : <Detail />}
				</section>
				<AnimatePresence exitBeforeEnter>
					{clipboardActive && <Clipboard />}
				</AnimatePresence>
				<footer className="container mx-auto">
					<div className="max-w-3xl mx-auto px-4 sm:px-6 lg:px-8 lg:max-w-7xl border-t border-gray-200 py-8 text-sm text-grayTextLight text-left">
						<p>
							Al utilizar el presente Sitio (
							<a href="https://talento.aeromexico.com">
								https://talento.aeromexico.com
							</a>
							), me obligo lisa y llanamente a deslindar de
							cualquier responsabilidad a Aerovías de México, S.A.
							de C.V. (“Aeroméxico”), sus funcionarios y
							cualesquiera de las empresas pertenecientes a Grupo
							Aeroméxico, respecto de:
						</p>
						<ul className="my-3">
							<li className="mb-1">
								(i) La información que proporcione en el Sitio,
								siendo el suscrito el único responsable del
								contenido y veracidad de la misma; y
							</li>
							<li>
								(ii) el uso que terceros hagan de dicha
								información.
							</li>
						</ul>
						<p>
							Reconozco, asimismo, que el beneficio que me otorga
							Aeroméxico y las pertenecientes a Grupo Aeroméxico
							al poder publicar mi información curricular, no me
							otorga derecho o expectativa de derecho alguno,
							comprometiéndome a respetar los términos y
							condiciones que Aeroméxico y sus empresas
							unilateralmente determinen para el uso del Sitio.
						</p>
					</div>
				</footer>
			</Route>
		</Switch>
	);
}

export default App;
