import React, { useContext, useEffect } from 'react';
import { FilterContext } from '../globalState';
import { motion } from 'framer-motion';

function Clipboard() {
	const { clipboardOff } = useContext(FilterContext);

	useEffect(() => {
		setTimeout(function () {
			clipboardOff();
		}, 6000);
	}, [clipboardOff]);

	return (
		<motion.div
			initial={{ opacity: 0, y: 100 }}
			animate={{ opacity: 1, y: 0 }}
			exit={{ opacity: 0, y: 100 }}
			className="fixed bottom-0 inset-x-0 pb-2 sm:pb-5"
		>
			<div className="max-w-7xl mx-auto px-2 sm:px-6 lg:px-8">
				<div className="p-2 rounded-lg bg-black shadow-lg sm:p-3">
					<div className="flex items-center justify-between flex-wrap">
						<div className="w-0 flex-1 flex items-center">
							<span className="flex p-2 rounded-lg bg-lightBlue">
								<svg
									xmlns="http://www.w3.org/2000/svg"
									width="24"
									height="24"
									viewBox="0 0 24 24"
									className="h-6 w-6 text-white fill-current"
								>
									<path d="M16 1H4c-1.1 0-2 .9-2 2v14h2V3h12V1zm3 4H8c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h11c1.1 0 2-.9 2-2V7c0-1.1-.9-2-2-2zm0 16H8V7h11v14z" />
								</svg>
							</span>
							<p className="ml-3 font-medium text-white truncate">
								<span className="md:hidden">
									¡La url se copió al portapapeles!
								</span>
								<span className="hidden md:inline font-medium">
									¡La url se copió al portapapeles! Utiliza
									Ctrl + V para compartirlo o Click derecho y
									pegar.
								</span>
							</p>
						</div>
						<div className="order-2 flex-shrink-0 sm:order-3 sm:ml-2">
							<button
								onClick={clipboardOff}
								type="button"
								className="-mr-1 flex p-2 rounded-md hover:bg-lightBlue focus:outline-none focus:ring-2 focus:ring-white"
							>
								<span className="sr-only">Dismiss</span>
								<svg
									className="h-6 w-6 text-white"
									xmlns="http://www.w3.org/2000/svg"
									fill="none"
									viewBox="0 0 24 24"
									stroke="currentColor"
									aria-hidden="true"
								>
									<path
										strokeLinecap="round"
										strokeLinejoin="round"
										strokeWidth="3"
										d="M6 18L18 6M6 6l12 12"
									/>
								</svg>
							</button>
						</div>
					</div>
				</div>
			</div>
		</motion.div>
	);
}

export default Clipboard;
