import React, { useState, createContext } from 'react';

export const FilterContext = createContext();

export function FilterProvider({ children }) {
	const [theUsers, setTheUsers] = useState([]);
	const [theModal, setTheModal] = useState(false);
	const [userId, setUserId] = useState(null);
	const [clipboardActive, setClipboardActive] = useState(false);
	const [linkedInData, setLinkedInData] = useState(null);

	const clipboardOn = () => {
		setClipboardActive(true);
	};

	const clipboardOff = () => {
		setClipboardActive(false);
	};

	const resetUsers = () => {
		setTheUsers([]);
	};

	const resetUserID = () => {
		setUserId(null);
	};

	return (
		<FilterContext.Provider
			value={{
				theUsers,
				setTheUsers,
				resetUsers,
				theModal,
				setTheModal,
				userId,
				setUserId,
				resetUserID,
				clipboardActive,
				clipboardOn,
				clipboardOff,
				linkedInData,
				setLinkedInData
			}}
		>
			{children}
		</FilterContext.Provider>
	);
}
