export const LINKEDIN_FORMATTER = (url) => {
	return !!url
		? url.includes('@')
			? `mailto:${url}`
			: url.includes('https://www.')
			? url.replace('https://www.', 'https://')
			: url.includes('www.')
			? url.replace('www.', 'https://')
			: url.includes('https://linkedin')
			? url
			: `https://www.linkedin.com/search/results/all/?keywords=${encodeURI(
					url
			  )}&origin=GLOBAL_SEARCH_HEADER`
		: null;
};
