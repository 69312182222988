import React, { useEffect, useState, useRef, useContext } from 'react';
import Ilustracion from '../../assets/header-image.png';
import Filters from './Filters';
import { FilterContext } from '../../globalState';
import HeaderDetail from './HeaderDetail';
import { AnimatePresence } from 'framer-motion';

function Header() {
	const [complex, setComplex] = useState(false);
	const selectedElement = useRef();
	const { theModal } = useContext(FilterContext);

	const handleFilter = () => {
		let scroll = window.scrollY || window.pageYOffset,
			boundsTop =
				selectedElement.current.getBoundingClientRect().top + scroll,
			viewport = {
				top: scroll
			},
			bounds = {
				bottom: boundsTop + selectedElement.current.clientHeight
			};
		if (bounds.bottom < viewport.top) {
			setComplex(true);
		} else {
			setComplex(false);
		}
	};

	useEffect(() => {
		handleFilter();
		document.addEventListener('scroll', handleFilter);
		window.addEventListener('resize', handleFilter);
		window.addEventListener('orientationchange', handleFilter);
		return () => {
			document.removeEventListener('scroll', handleFilter);
			window.removeEventListener('resize', handleFilter);
			window.removeEventListener('orientationchange', handleFilter);
		};
	}, []);

	return (
		<>
			<section className="container-fluid bg-white">
				<article className="container mx-auto px-10 flex flex-wrap items-center justify-center py-6 md:py-12">
					<div className="left w-full md:w-6/12 order-2 md:order-1">
						<h2 className="text-lg text-lightBlue font-medium">
							Directorio de Talento
						</h2>
						<h1 className="text-3xl sm:text-4xl md:text-5xl text-black font-medium mt-2 mb-4 leading-none">
							Ellos nos ayudaron a{' '}
							<br className="hidden xl:block" />
							conectar a México con el mundo
						</h1>
						<p
							ref={selectedElement}
							className="text-xl text-black leading-tight"
						>
							Conoce nuevos perfiles que podrán ayudarte a
							complementar tu equipo.
						</p>
						<p className="text-sm text-grayText mt-2">
							Si eres ex empleado de Grupo Aeroméxico y deseas
							publicar, editar o eliminar tu perfil profesional,
							envía un correo a{' '}
							<a
								// onClick={() =>
								// 	window.umami.trackEvent(
								// 		'Link a Centro de Ayuda RH',
								// 		'centroAyuda_btn'
								// 	)
								// }
								href="mailto:amcentrodeayudarh@aeromexico.com"
								target="_blank"
								rel="noreferrer"
								className="grag text-lightBlue underline"
							>
								amcentrodeayudarh@aeromexico.com
							</a>
						</p>
					</div>
					<div className="right w-full md:w-6/12 mb-8 lg:mb-0 hidden md:flex items-center justify-center order-1 md:order-2">
						<img
							className="w-8/12 md:w-auto"
							src={Ilustracion}
							alt="Directorio de Talento"
						/>
					</div>
				</article>
				<div className="w-full h-border bg-border"></div>
			</section>
			<section className="filters container-fluid bg-white sticky top-nav z-50">
				<article
					className={`container mx-auto px-10 transition duration-200 ${
						complex
							? 'pt-4 pb-4'
							: theModal
							? 'pt-4 pb-4'
							: 'pt-8 pb-6'
					}`}
				>
					<AnimatePresence exitBeforeEnter>
						{!theModal ? (
							<Filters showTitle={complex} />
						) : (
							<HeaderDetail />
						)}
					</AnimatePresence>
				</article>
				<div className="w-full h-border bg-border"></div>
			</section>
		</>
	);
}

export default Header;
