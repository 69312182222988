import React, { useContext } from 'react';
import { FilterContext } from '../../globalState';
import { motion } from 'framer-motion';
import { useHistory } from 'react-router-dom';

function HeaderDetail(props) {
	const history = useHistory();
	const { setTheModal, setUserId, resetUserID } = useContext(FilterContext);
	const urlParams = new URLSearchParams(window.location.search);
	setUserId(urlParams.get('id'));

	const childVariant = {
		init: {
			opacity: 0,
			x: -50
		},
		anim: {
			opacity: 1,
			x: 0,
			transition: {
				delay: 0.25
			}
		},
		anim2: {
			opacity: 1,
			x: 0,
			transition: {
				delay: 0.5
			}
		},
		end: {
			opacity: 0,
			x: -50
		}
	};

	return (
		<motion.div
			initial={{ opacity: 0 }}
			animate={{ opacity: 1 }}
			exit={{ opacity: 0 }}
			key="detail-filter-header"
			className="w-full px-2 flex items-center justify-start overflow-hidden"
		>
			<motion.div
				variants={childVariant}
				initial="init"
				animate="anim"
				exit="end"
				onClick={() => {
					history.push({
						search: ''
					});
					setTheModal(false);
					resetUserID();
					// window.umami.trackEvent('click', 'backHome_btn');
				}}
				className="return flex flex-col items-center justify-center text-grayText cursor-pointer hover:text-black"
			>
				<svg
					xmlns="http://www.w3.org/2000/svg"
					width="24"
					height="24"
					fill="none"
					stroke="currentColor"
					strokeLinecap="round"
					strokeLinejoin="round"
					strokeWidth="2"
					className="w-10 h-10"
					viewBox="0 0 24 24"
				>
					<path stroke="none" d="M0 0h24v24H0z" />
					<path d="M9 13l-4 -4l4 -4m-4 4h11a4 4 0 0 1 0 8h-1" />
				</svg>
				<p className="text-xs">Regresar</p>
			</motion.div>
			<motion.h2
				variants={childVariant}
				initial="init"
				animate="anim2"
				exit="end"
				className="text-2xl text-black font-medium ml-6 mt-1 leading-none"
			>
				Detalle <br />
				de usuario
			</motion.h2>
		</motion.div>
	);
}

export default HeaderDetail;
