import React, { useContext } from 'react';
import Card from './Card';
import { FilterContext } from '../globalState';
import Masonry from 'react-masonry-css';
import { AnimatePresence } from 'framer-motion';

const breakpointColumnsObj = {
	default: 4,
	1535: 3,
	1023: 2,
	640: 1
};

function Cards() {
	const { theUsers } = useContext(FilterContext);

	return (
		<AnimatePresence>
			{theUsers.length < 1 && (
				<h2 className="text-blue w-full text-center animate-pulse font-medium text-xl">
					cargando...
				</h2>
			)}
			<Masonry
				breakpointCols={breakpointColumnsObj}
				className="w-full flex mt-2 masonry-css-grid"
				columnClassName="card_columns_masonry"
			>
				{theUsers.map((user, index) => (
					<Card
						index={index}
						key={user.name}
						employee={user.employee}
						name={user.name}
						relocation={user.relocation}
						location={user.location}
						file={!!user.file ? user.file.url : null}
						expertise={user.expertise}
						presentation={
							!!user.presentation ? user.presentation : null
						}
						position={user.position}
						linkedin={user.linkedIn === '' ? null : user.linkedIn}
					/>
				))}
			</Masonry>
		</AnimatePresence>
	);
}

export default Cards;
